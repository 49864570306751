import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/lutheran/1-min.png"
import img1 from "../../../assets/images/portfolio_items/lutheran/2-min.png"
import img2 from "../../../assets/images/portfolio_items/lutheran/4-min.png"
import img3 from "../../../assets/images/portfolio_items/lutheran/5-min.png"
import img4 from "../../../assets/images/portfolio_items/lutheran/6-min.png"

export default () => 
<div>
    <SEO title={' ICON Worldwide | Lutheran World Federation - Drupal Development'} 
    description="Drupal website development for Lutheran World Federation discover more on ICON's portfolio!"
    canonical={'https://icon-worldwide.com/works/lutheran'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt=" Lutheran, ICON Worldwide portfolio, Drupal website development" title="ICON Worldwide portfolio, Lutheran, Drupal website development"/>
            <div className="title"><h1>Lutheran World Federation<span></span></h1>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>The LWF is a global organisation based in Geneva, Switzerland.  Our website redesign and development features dynamic content, hand-drawn iconography, and beauty across all screen sizes.  The Drupal platform includes custom language and country versions.</p>
        <div className="portfolio-images">
            <img src={img1} alt=" Lutheran, ICON Worldwide portfolio, Drupal website development" title="ICON Worldwide portfolio, Lutheran, Drupal website development"/>
            <img src={img2} alt=" Lutheran, ICON Worldwide portfolio, Drupal website development" title="ICON Worldwide portfolio, Lutheran, Drupal website development"/>
            <img src={img3} alt=" Lutheran, ICON Worldwide portfolio, Drupal website development" title="ICON Worldwide portfolio, Lutheran, Drupal website development"/>
        </div>
        <img className="full-img desktop-img" src={img4} alt=" Lutheran, ICON Worldwide portfolio, Drupal website development" title="ICON Worldwide portfolio, Lutheran, Drupal website development"/>
        <WorkFooter previous="swise" next="simple-risk"/>
        </div>
    </div>
</div>